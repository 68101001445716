import { Box, TableRow, Typography, styled } from '@mui/material';

import './styles.scss';

export const BaseTypography = styled(Typography)(() => ({
  fontFamily: 'Fieldwork',
}));

export const PdfMainHeader = styled(BaseTypography)(() => ({
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 700,
  color: '#333333',
  letterSpacing: '0.5px',
  marginBottom: '12px',
}));

export const PdfSummaryItem = styled(BaseTypography)(() => ({
  fontSize: '12px',
  lineHeight: '20px',
  fontWeight: 400,
  color: '#7D7D7D',
  letterSpacing: '0.5px',
}));

export const PdfScore = styled(BaseTypography)(() => ({
  fontWeight: 400,
  fontSize: '32px',
  color: '#333333',
  letterSpacing: '0.5px',
}));

export const PdfScoreLabel = styled(BaseTypography)(() => ({
  fontSize: '12px',
  lineHeight: '12px',
  fontWeight: 300,
  letterSpacing: '0.25px',
  color: '#7D7D7D',
}));

export const PdfExpectedOutcome = styled(BaseTypography)(() => ({
  fontWeight: 400,
  fontSize: '13px',
  lineHeight: '16px',
  marginTop: '-4px',
}));

export const BlockHeader = styled(BaseTypography)(() => ({
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '18px',
  letterSpacing: '0.5px',
}));

export const DetailsLabel = styled(BaseTypography)(() => ({
  fontSize: '12px',
  lineHeight: '20px',
  fontWeight: 700,
  letterSpacing: '0.25px',
  whiteSpace: 'nowrap',
}));

export const DetailsValue = styled(BaseTypography)(() => ({
  fontSize: '12px',
  lineHeight: '20px',
  fontWeight: 400,
  letterSpacing: '0.25px',
  display: 'inline-block',
  overflowWrap: 'break-word',
  wordWrap: 'break-word',
  maxWidth: '80%',
}));

export const AreaPhotosTitle = styled(BaseTypography)(() => ({
  fontSize: '12px',
  lineHeight: '18px',
  fontWeight: 300,
  letterSpacing: '0.5px',
  color: '#333333',
  marginBottom: '8px',
}));

export const AreaScoreBg = styled(Box)(() => ({
  borderRadius: '6px',
  padding: '2px 4px',
}));

export const AreaScore = styled(BaseTypography)(() => ({
  fontSize: '11px',
  lineHeight: '16px',
  letterSpacing: '0.4px',
}));

export const TotalScore = styled(BaseTypography)(() => ({
  fontSize: '14px',
  lineHeight: '24px',
  letterSpacing: '0.5px',
  color: '#7D7D7D',
}));
export const TotalScorePercentage = styled(BaseTypography)(() => ({
  fontSize: '20px',
  lineHeight: '24px',
  letterSpacing: '0.5px',
  color: '#333333',
}));

export const BulletText = styled(BaseTypography)(() => ({
  fontSize: '12px',
  lineHeight: '20px',
  fontWeight: 300,
  letterSpacing: '0.25px',
  display: 'inline-block',
}));

export const Bullet = styled('div')(() => ({
  width: '6px',
  height: '6px',
  borderRadius: '50%',
  background: '#FF874F',
}));

export const CustomTableRow = styled(TableRow)(() => ({
  fontFamily: 'Fieldwork',
  backgroundColor: '#fff',

  '&:nth-of-type(even)': {
    backgroundColor: '#FCFCFC',
  },

  '&:nth-of-type(odd)': {
    backgroundColor: '#FFFFFF',
  },

  '& > .MuiTableCell-root.MuiTableCell-head:first-of-type, & > .MuiTableCell-root.MuiTableCell-body:first-of-type':
    {
      borderRight: '1px solid #EBEBEB',
    },

  '& > .MuiTableCell-root': {
    paddingTop: '8px',
    paddingBottom: '8px',
  },

  '& > .MuiTableCell-root.MuiTableCell-head': {
    fontSize: '11px',
    lineHeight: '18px',
    letterSpacing: '0.5px',
    fontWeight: 700,
    color: '#333333',
  },
  '& > .MuiTableCell-root.MuiTableCell-body': {
    fontSize: '11px',
    lineHeight: '18px',
    letterSpacing: '0.5px',
    fontWeight: 400,
    color: '#333333',
    border: 'none',
  },
}));

export const SectionTableRow = styled(CustomTableRow)(() => ({
  '& > .MuiTableCell-root.MuiTableCell-head:first-of-type, & > .MuiTableCell-root.MuiTableCell-body:first-of-type':
    {
      borderRight: 'none',
    },
  borderBottom: '1px solid #EBEBEB',
}));

export const SectionName = styled(Typography)(() => ({
  fontSize: '11px',
  lineHeight: '18px',
  letterSpacing: '0.5px',
  fontWeight: 'bold',
  fontFamily: 'Fieldwork',
  color: '#FF5100',
}));

export const SectionScore = styled(SectionName)(() => ({
  color: '#333333',
}));
