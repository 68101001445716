import { useRef } from 'react';
import { MaintenanceRequestPdf } from '../MaintenanceRequestPDF';
import { PDFExport } from '@progress/kendo-react-pdf';
import { Button } from '@mui/material';

const MaintenanceRequestDownloadPDF = () => {
  const pdfExportComponent = useRef<PDFExport | null>(null);

  return (
    <>
      <Button
        variant='text'
        onClick={() => {
          if (pdfExportComponent.current) {
            pdfExportComponent.current.save();
          }
        }}
        id='pdf-export'
      >
        Export to PDF
      </Button>
      <MaintenanceRequestPdf ref={pdfExportComponent} />
    </>
  );
};

export default MaintenanceRequestDownloadPDF;
