import { MaintenanceRequestPriority } from '../constants';

export const getPriorityBgColor = (priority?: MaintenanceRequestPriority) => {
  switch (priority) {
    case MaintenanceRequestPriority.Low:
      return '#D9D9D9';
    case MaintenanceRequestPriority.Medium:
      return '#F6E4A2';
    case MaintenanceRequestPriority.High:
      return '#F8C9BB';
    case MaintenanceRequestPriority.Critical:
      return '#D32F2F';
    default:
      return '#D9D9D9';
  }
};
