import { Box, Grid, Stack } from '@mui/material';
import { PDFExport } from '@progress/kendo-react-pdf';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import CLogo from 'assets/png/pdf-template/c-logo.png';
import { ReactComponent as BuildingSvg } from 'assets/svg/pdf-template/hotel-fill.svg';
import { getPriorityBgColor } from 'modules/maintenance-request/helpers';
import {
  BlockHeader,
  DetailsLabel,
  DetailsValue,
  PdfMainHeader,
  PdfSummaryItem,
} from 'modules/pdf/components';
import { PageTemplate } from 'modules/pdf/pageTemplate';
import moment from 'moment';
import { forwardRef } from 'react';
import { useParams } from 'react-router';
import { useGetMaintenanceRequestItemQuery } from 'store/services/maintenanceRequest';
import { MaintenanceRequestPriority } from '../constants';

type RefType = PDFExport | null;

export const MaintenanceRequestPdf = forwardRef<RefType, any>((_, ref) => {
  const { maintenance: maintenanceRequestId } = useParams<{
    maintenance: string;
  }>();

  const { data: maintenanceRequestItem } = useGetMaintenanceRequestItemQuery(
    maintenanceRequestId ? { id: maintenanceRequestId } : skipToken,
  );

  if (!maintenanceRequestItem) return null;

  return (
    <Box my={10}>
      <PDFExport
        keepTogether='section'
        forcePageBreak='.page-break'
        pageTemplate={() => (
          <PageTemplate footerText={maintenanceRequestItem.externalSystemRefId} />
        )}
        repeatHeaders
        paperSize='A4'
        margin={{ right: 0, bottom: 60, left: 0 }}
        fileName={`${maintenanceRequestItem.externalSystemRefId}.pdf`}
        ref={ref}
      >
        {/* HEADER ---- start */}
        <Box bgcolor='#FFF7F2' p={4}>
          <Stack direction='row' alignItems='center'>
            <Box mr={3}>
              <img
                src={maintenanceRequestItem.orgLogo?.urlPath ?? CLogo}
                alt='KendoReact PDF Generator embedded image'
                height='60px'
              />
            </Box>

            <Box>
              <PdfMainHeader>Maintenance request</PdfMainHeader>
              <Stack rowGap='4px'>
                <Stack direction='row' alignItems='center' spacing={1.5}>
                  <BuildingSvg />
                  <PdfSummaryItem>{maintenanceRequestItem.siteView?.name}</PdfSummaryItem>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Box>
        {/* HEADER ---- end */}
        {/* GENERAL DETAILS ---- start */}
        <section>
          <Box px={4} mt={4}>
            <Box mb={2.5}>
              <BlockHeader>General Details</BlockHeader>
            </Box>
            <Box
              px={2}
              py={1.5}
              border='1px solid rgba(235, 235, 235, 1)'
              borderRadius='6px'
              bgcolor='#FCFCFC'
            >
              <Stack direction='row' gap={1} mb={1}>
                <DetailsLabel>Site name:</DetailsLabel>
                <DetailsValue>{maintenanceRequestItem.siteView?.name}</DetailsValue>
              </Stack>
              {maintenanceRequestItem.subSiteView && (
                <Stack direction='row' gap={1} mb={1}>
                  <DetailsLabel>Subsite name:</DetailsLabel>
                  <DetailsValue>{maintenanceRequestItem?.subSiteView?.name}</DetailsValue>
                </Stack>
              )}

              <Stack direction='row' gap={1} mb={1}>
                <DetailsLabel>Date:</DetailsLabel>
                <DetailsValue>
                  {maintenanceRequestItem.createdOn
                    ? moment(maintenanceRequestItem.createdOn).format('DD/MM/YYYY')
                    : ''}
                </DetailsValue>
              </Stack>

              <Stack direction='row' gap={1} mb={1}>
                <DetailsLabel>Issue type:</DetailsLabel>
                <DetailsValue>{maintenanceRequestItem?.issueName}</DetailsValue>
              </Stack>
              <Stack direction='row' columnGap={1} alignItems='center' mb={1}>
                <DetailsLabel>Priority:</DetailsLabel>
                <Box>
                  <Box
                    sx={{
                      backgroundColor: getPriorityBgColor(
                        maintenanceRequestItem.requestPriority as MaintenanceRequestPriority,
                      ),
                      padding: '4px 12px',
                      borderRadius: '20px',
                      whiteSpace: 'nowrap',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <DetailsValue>{maintenanceRequestItem?.requestPriority}</DetailsValue>
                  </Box>
                </Box>
              </Stack>
              <Stack direction='row' gap={1} mb={1}>
                <DetailsLabel>Created by:</DetailsLabel>
                <DetailsValue>{maintenanceRequestItem?.userAutor?.name}</DetailsValue>
              </Stack>
              <Stack direction='row' gap={1} mb={1}>
                <DetailsLabel>Description:</DetailsLabel>
                <DetailsValue>{maintenanceRequestItem?.description}</DetailsValue>
              </Stack>
            </Box>
          </Box>
        </section>
        {/* GENERAL DETAILS ---- end */}

        {maintenanceRequestItem?.documentList?.length ? (
          <>
            <section>
              <Box px={4}>
                <section>
                  <Box mt={2.5}>
                    <Grid container spacing={1}>
                      {maintenanceRequestItem.documentList.map((item) => (
                        <Grid item xs={4} key={item.id} textAlign='center'>
                          <img
                            src={item.urlPath}
                            alt={item.fileName}
                            style={{
                              objectFit: 'contain',
                              maxWidth: '100%',
                              maxHeight: '95%',
                              borderRadius: '6px',
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </section>
              </Box>
            </section>
          </>
        ) : null}
      </PDFExport>
    </Box>
  );
});
