import { IBuildingPayload, IFile } from 'interfaces/responses';

export enum MaintenanceRequestPriority {
  Critical = 'Critical',
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
}

export enum MaintenanceRequestStatus {
  Open = 'Open',
  InProgress = 'In Progress',
  AssignedToExternalResource = 'Assigned To External Resource',
  Resolved = 'Resolved',
}

export const priorityData = [
  { id: MaintenanceRequestPriority.Low, name: 'Low' },
  { id: MaintenanceRequestPriority.Medium, name: 'Medium' },
  { id: MaintenanceRequestPriority.High, name: 'High' },
  { id: MaintenanceRequestPriority.Critical, name: 'Critical' },
];

export const requestStatus = [
  { id: MaintenanceRequestStatus.Open, name: 'Open' },
  { id: MaintenanceRequestStatus.InProgress, name: 'In Progress' },
  {
    id: MaintenanceRequestStatus.AssignedToExternalResource,
    name: 'Assigned To External Resource',
  },
  { id: MaintenanceRequestStatus.Resolved, name: 'Resolved' },
];

export const FORM_DEFAULTS = {
  issueType: '',
  site: null as IBuildingPayload | null,
  subsite: '',
  assignee: '',
  completionDate: null as Date | null,
  notedDate: null as Date | null,
  requestPriority: '',
  description: '',
  status: '',
  mediaFiles: [] as IFile[],
};

export const CORRECTIVE_ACTION_FORM_DEFAULT = {
  completedBy: '',
  timeToComplete: null as Date | null,
  internalCost: '',
  chargeableCost: '',
  description: '',
};
