import { IMaintenanceRequest } from 'interfaces/responses';
import { removeFalsyKeys } from 'modules/common/helpers/removeFalsyKeys';
import { baseApi } from 'store/services/base';

export const maintenanceRequestApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getMaintenanceRequestItemsList: builder.query<
      IMaintenanceRequest[],
      { organizationId?: string }
    >({
      query: (data) => {
        const params = data ? new URLSearchParams(removeFalsyKeys(data)).toString() : '';

        return {
          url: `MaintenanceRequest/getall?${params}`,
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'MaintenanceRequest' as const, id })),
              'MaintenanceRequest',
            ]
          : ['MaintenanceRequest'],
    }),
    getMaintenanceRequestBySiteIdItemsList: builder.query<
      IMaintenanceRequest[],
      { siteId: string }
    >({
      query: (data) => {
        const params = data ? new URLSearchParams(removeFalsyKeys(data)).toString() : '';
        return {
          url: `MaintenanceRequest/getBySiteId?${params}`,
        };
      },
      transformResponse: (response: IMaintenanceRequest[]) =>
        response.map((request) => ({
          ...request,
          createdBy: request.userAutor?.name ?? '',
        })),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'MaintenanceRequest' as const, id })),
              'MaintenanceRequest',
            ]
          : ['MaintenanceRequest'],
    }),
    getMaintenanceRequestItem: builder.query<IMaintenanceRequest, { id: string }>({
      query: ({ id }) => ({
        url: `MaintenanceRequest/getById?itemId=${id}`,
      }),
      providesTags: ['MaintenanceRequestItem'],
    }),
    createMaintenanceRequestItem: builder.mutation<
      IMaintenanceRequest,
      Partial<
        Omit<IMaintenanceRequest & { documents: { uploadedFileId: string }[] }, 'documentList'>
      >
    >({
      query: (credentials) => ({
        url: 'MaintenanceRequest/create',
        method: 'POST',
        body: credentials,
      }),
      invalidatesTags: ['MaintenanceRequest'],
    }),
    updateMaintenanceRequestItem: builder.mutation<
      IMaintenanceRequest,
      Partial<
        Omit<IMaintenanceRequest & { documents: { uploadedFileId: string }[] }, 'documentList'>
      >
    >({
      query: (credentials) => ({
        url: 'MaintenanceRequest/update',
        method: 'PUT',
        body: credentials,
      }),
      invalidatesTags: ['MaintenanceRequest', 'MaintenanceRequestItem'],
    }),

    deleteMaintenanceRequestItem: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `MaintenanceRequest/delete?entityId=${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['MaintenanceRequest'],
    }),
    maintenanceEmailRequest: builder.mutation<
      void,
      { maintenanceRequestId: string; maintenanceEmail: string; comment: string }
    >({
      query: (payload) => ({
        url: `MaintenanceRequest/emailRequest`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['MaintenanceRequest'],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetMaintenanceRequestItemsListQuery,
  useGetMaintenanceRequestBySiteIdItemsListQuery,
  useGetMaintenanceRequestItemQuery,
  useCreateMaintenanceRequestItemMutation,
  useUpdateMaintenanceRequestItemMutation,
  useDeleteMaintenanceRequestItemMutation,
  useMaintenanceEmailRequestMutation,
} = maintenanceRequestApi;
