import CTLogo from 'assets/png/pdf-template/ct-logo-pdf.png';
import './styles.scss';

interface Props {
  footerText?: string;
}

export const PageTemplate = (props: Props) => {
  return (
    <div
      style={{
        position: 'absolute',
        bottom: '0px',
        left: '0px',
        background: '#260F68',
        height: '40px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <div style={{ height: '100%', display: 'flex', alignItems: 'center', paddingLeft: '50px' }}>
        <img src={CTLogo} style={{ maxHeight: '50%' }} alt='Cleantracker' />
      </div>
      <div
        style={{
          fontFamily: 'Fieldwork',
          fontSize: '12px',
          letterSpacing: '0.25',
          color: '#fff',
          paddingRight: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        {props.footerText}
        {/* <div>{props.name && props.date && `${props.name} | ${props.date}`}</div> */}
      </div>
    </div>
  );
};
